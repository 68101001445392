import type { permissionList } from "@/@layouts/types";
import navItems from "@/navigation/vertical";
import type { UserRecordModel } from "@/utils/PocketBaseAdapter";
import { pb, RoleRecordModel } from "@/utils/PocketBaseAdapter";
import { usePermission } from "@/utils/usePermission";
import isEmpty from "lodash/isEmpty";

export const useUserRole = () => {
  // Interface
  interface permissionMap {
    [key: string]: permissionList;
  }

  // composable
  const usePermissions = usePermission();
  const { isDisable } = usePermissions;

  // Data
  const rolesList = ref<any[]>([]);
  const userInfo = ref<any>({});
  const currentLocation = ref<any>(
    localStorage.getItem("homeLocation") ||
      (pb.authStore.model as UserRecordModel)?.homeLocation
  );
  const permissionPriority = ["Create", "Edit", "View", "Assign", "None"];
  const permission = ref<any>();
  const refetchPermission = ref<number>(1);

  function safelyParseJSON(json) {
    // This function cannot be optimised, it's best to
    // keep it small!
    var parsed;
    try {
      parsed = JSON.parse(json);
    } catch (e) {
      // Oh well, but whatever...
    }
    return parsed; // Could be undefined!
  }

  const isUserMode = ref<any>(
    safelyParseJSON(sessionStorage.getItem("userMode")) || "false"
  );

  const permissionListMap = ref<permissionMap>({});

  // Computed
  const calculatePermissions = async () => {
    const relevantRoles = rolesList.value?.filter(
      (role) =>
        userInfo.value?.roles?.includes(role.id) &&
        role.location === currentLocation.value
    );
    const aggregatedPermissions = relevantRoles?.reduce((acc, role) => {
      Object.keys(role).forEach((permissionKey) => {
        if (!acc[permissionKey]) {
          acc[permissionKey] = new Set();
        }
        acc[permissionKey].add(role[permissionKey]);
      });
      return acc;
    }, {});

    Object.keys(aggregatedPermissions)?.forEach((permissionKey) => {
      const permissionsMap = Array.from(aggregatedPermissions[permissionKey]);
      if (permissionsMap.some((el: any) => permissionPriority?.includes(el))) {
        aggregatedPermissions[permissionKey] =
          permissionPriority?.find((permission) =>
            permissionsMap?.includes(permission)
          ) || "None";
      } else {
        aggregatedPermissions[permissionKey] = permissionsMap;
      }
    });

    return await aggregatedPermissions;
  };

  /**
   * Computed property to generate the list of navigation items based on user permissions.
   *
   * @returns {Array} - An array of navigation items with their 'disable' property set based on user permissions.
   */
  const navListItems = computed(() => {
    return navItems.reduce((acc: any, el: any) => {
      // Determine if the navigation item should be disabled based on user permissions.
      const disable = el.disable ? el.disable : isDisable(el, permission.value);

      // If the navigation item does not require a permission or the user has the required permission,
      // add it to the list with the 'disable' property set accordingly.
      if (
        !el.permissionRequired ||
        (permission.value &&
          permission.value[el.permissionRequired] &&
          permission.value[el.permissionRequired].includes(true))
      ) {
        acc.push({ ...el, disable });
      }

      return acc;
    }, []);
  });

  const getUserRolePermission = async () => {
    try {
      if (!rolesList.value.length) {
        const recordList = await pb
          .collection("roles")
          .getFullList<RoleRecordModel>();

        rolesList.value = recordList;
        refetchPermission.value = Math.random();
      }

      if (
        !isEmpty(userInfo.value) &&
        pb?.authStore?.model?.id === userInfo?.value?.id
      ) {
        return;
      } else {
        const record = await pb
          .collection("users")
          .getOne(pb?.authStore?.model?.id, { requestKey: "userprofile" });
        userInfo.value = record;
      }
    } catch (e) {}
  };

  const havePermission = async (requestedPath: string) => {
    currentLocation.value =
      localStorage.getItem("homeLocation") ||
      (pb.authStore.model as UserRecordModel)?.homeLocation;

    if (isEmpty(permission.value)) {
      permission.value = await calculatePermissions();
    }

    // Mapping of paths to permission keys and required conditions
    const permissionMap: Record<string, { key: string; adminCheck?: boolean }> =
      {
        pos: { key: "dockets" },
        "pos-checkout": { key: "dockets" },
        dockets: { key: "dockets" },
        "dockets-view-id": { key: "dockets" },
        products: { key: "products", adminCheck: true },
        user: { key: "users", adminCheck: true },
        reports: { key: "reporting", adminCheck: true },
        memberships: { key: "memberships", adminCheck: true },
        giftCards: { key: "giftCards", adminCheck: true },
        coupontypes: { key: "coupons", adminCheck: true },
        activities: { key: "activities", adminCheck: true },
        events: { key: "events", adminCheck: true },
      };

    // Get permissions for the requested path
    const permissionData = permissionMap[requestedPath];

    if (permissionData) {
      const { key, adminCheck } = permissionData;
      const hasBasicPermission =
        permission.value[key] && permission.value?.[key] !== "None";
      const meetsAdminCondition = adminCheck
        ? permission.value["canSeeAdmin"]?.includes(true)
        : permission.value["canSeeApps"]?.includes(true);

      return hasBasicPermission && meetsAdminCondition;
    }

    // Default case
    return true;
  };

  const clearPermissionStore = () => {
    permission.value = {};
    permissionListMap.value = {};
    rolesList.value = [];
    currentLocation.value = "";
    userInfo.value = null;
  };

  watch(
    () => refetchPermission.value,
    () => {
      permission.value = calculatePermissions();
    },
    { immediate: true, deep: true }
  );

  return {
    permission,
    permissionListMap,
    navListItems,
    rolesList,
    currentLocation,
    isUserMode,
    getUserRolePermission,
    havePermission,
    clearPermissionStore,
  };
};
