<script setup lang="ts">
import { pb } from "@/utils/PocketBaseAdapter";
import { usePriorityBooking } from "@/store/usePriorityBookings";
import { useEventStore } from "@/store/useEventStore";
import { useCalendarStore } from "@/store/useCalendarStore";
import { useUserStore } from "@/utils/UserStore";
import { useUserRole } from "@/utils/useUserRole";
import { useGlobalSetupStore } from "@/utils/GlobalSetupStore";
import { useListStore } from "@/store/listStore";
import { moment } from "@/utils/useTimeZone";
import isEmpty from "lodash/isEmpty";

// Interface
interface Area {
  location: number;
}

interface Location {
  id: number;
  name: string;
}

// store
const priorityBooking = usePriorityBooking();
const events = useEventStore();
const calendar = useCalendarStore();
const userStore = useUserStore();
const getSetupStore = useGlobalSetupStore();
const userRole = useUserRole();
const list = useListStore();

const { handleEventEdit } = events;
const { deleteBooking, getUserList } = calendar;
const { getListArea, fetchActivities } = list;

const { getPriorityBookings } = priorityBooking;
const { getLocationList } = list;
const { priorityBookings, isLoading, isConfirmEditBookingDialog } =
  storeToRefs(priorityBooking);
const {
  addEditEventDialog,
  eventData,
  recurringDetails: eventRecurringDetails,
  confirmEditDialog,
  selectedEvent,
} = storeToRefs(events);
const {
  usersList,
  recurringDetails,
  isBookingStepperVisible,
  editBookingData,
} = storeToRefs(calendar);
const { currentLocation } = storeToRefs(userStore);
const { globalSetup } = storeToRefs(getSetupStore);
const { permissionListMap, userInfo, rolesList } = storeToRefs(userRole);
const { listArea, activityList, locationList } = storeToRefs(list);

// Data
const recurringConfig = ref<any>("");
const isQrCodeVisible = ref<boolean>(false);
const selectedBookingId = ref<string>("");

// computed
const getMyRole = computed(() => {
  return rolesList?.value?.filter((role: any) =>
    userInfo.value?.roles?.includes(role.id)
  );
});

const getLocation = computed(() => (area: Area) => {
  const locations = locationList.value || [];
  const location = locations.find((loc: Location) => loc.id === area.location);
  return location?.name || "";
});

const userId = computed(() => pb.authStore.model?.id);

const getDay = computed(() => {
  const today = moment().startOf("day");
  const tomorrow = moment(today).add(1, "day");

  return (dateTime: string) => {
    const bookingDate = moment(dateTime).startOf("day");

    if (bookingDate.isSame(today)) {
      return "Today";
    } else if (bookingDate.isSame(tomorrow)) {
      return "Tomorrow";
    }
    return bookingDate.format("dddd, DD-MM");
  };
});

// Methods
const handleEdit = (booking: any) => {
  // Navigate to booking edit page

  const {
    recurringBookingDetails,
    eventDetails,
    recurringEventDetails,
    expand,
  } = booking;

  // manage recurring booking dialog
  const baseBookingData = {
    ...booking,
    startDate: booking?.startTime,
    docketId: expand?.docketLine?.docket,
  };

  if (!isEmpty(recurringEventDetails)) {
    // Manage recurring event dialog
    const { id, events, frequency, interval, days, endDate, expand } =
      recurringEventDetails;

    const eventData = expand?.events?.find((event: any) =>
      event.expand?.bookings?.some((book: any) => book.id === booking?.id)
    );

    if (eventData) {
      selectedEvent.value = {
        ...eventData,
        recurring: {
          recurringId: id,
          events,
          frequency,
          interval,
          days,
          endDate,
        },
      };
      eventRecurringDetails.value = {
        isEditAll: false,
        recurringId: id,
        events,
      };
      isConfirmEditBookingDialog.value = true; // Show warning dialog
      confirmEditDialog.value = {
        type: "Event",
        title: "Edit Recurring Event",
        alertMessage:
          "This is one event in a series. What would you like to do?",
      };
    }
    return;
  }
  if (!isEmpty(eventDetails)) {
    // Manage event dialog
    confirmEditDialog.value = {
      type: "Event",
      title: "Edit Event",
      alertMessage: "Edit details for this event.",
    };
    handleEventEdit(eventDetails);
    return;
  }
  if (!isEmpty(recurringBookingDetails)) {
    const { interval, days, frequency, endDate } = recurringBookingDetails;
    editBookingData.value = {
      ...baseBookingData,
      recurring: { interval, days, frequency, endDate },
    };
    recurringConfig.value = recurringBookingDetails;
    isConfirmEditBookingDialog.value = true;
    confirmEditDialog.value = {
      type: "Booking",
      title: "Edit Recurring Booking",
      alertMessage:
        "This is one booking in a series. What would you like to do?",
    };
    return;
  }

  // Normal booking edit
  editBookingData.value = baseBookingData;
  isBookingStepperVisible.value = true;
};

const closeDialog = (value: boolean) => {
  isBookingStepperVisible.value = value;
  editBookingData.value = {};
};

const handleBookingEdit = (editType: string) => {
  /**
   * Handles the editing of a booking, recurring booking, or event.
   *
   * @param {string} editType - The type of edit to be performed. If 'ALL', all related bookings will be edited.
   *
   * @returns {void}
   */
  recurringDetails.value = {
    isEditAll: editType && editType === "ALL" ? true : false,
    recurringId: recurringConfig?.value?.id,
    bookings: recurringConfig?.value?.bookings,
  };

  isBookingStepperVisible.value = true;
  isConfirmEditBookingDialog.value = false;
};

watch(currentLocation.value, async (newVal) => {
  if (newVal) {
    await fetchActivities();
  }
});

// Hooks
onMounted(async () => {
  await getLocationList();
  await getListArea();
  await getPriorityBookings(userId.value);
  if (currentLocation.value) {
    await fetchActivities();
  }
});
</script>

<template>
  <VCard
    v-if="priorityBookings.length"
    class="bookings-container"
    :class="{
      dark_theme: $vuetify.theme.current.dark,
      light_theme: !$vuetify.theme.current.dark,
    }"
  >
    <VCardTitle class="title">Upcoming bookings</VCardTitle>

    <VRow v-if="isLoading">
      <VCol v-for="n in 2" :key="n" cols="12" md="6" lg="3">
        <div class="booking-skeleton">
          <VCard class="booking-card skeleton animated-shimmer">
            <VCardText>
              <div class="skeleton-header shimmer"></div>
              <div class="skeleton-chips">
                <div class="skeleton-chip shimmer"></div>
                <div class="skeleton-chip shimmer"></div>
              </div>
              <div class="skeleton-details">
                <div class="skeleton-detail shimmer"></div>
              </div>
            </VCardText>
          </VCard>
        </div>
      </VCol>
    </VRow>

    <VRow v-else>
      <VCol
        v-for="booking in priorityBookings"
        :key="booking.id"
        cols="12"
        md="6"
        lg="3"
      >
        <VCard
          class="booking-card pb-0"
          @click="handleEdit(booking)"
          :class="{ 'due-card': booking.due > 0 }"
        >
          <!-- Icon for recurring booking in top-right corner -->
          <VIcon
            v-if="
              !isEmpty(booking.recurringEventDetails) ||
              !isEmpty(booking.recurringBookingDetails)
            "
            class="top-right-icon recurring-icon"
            color="black"
          >
            mdi-calendar-outline
          </VIcon>

          <!-- Icon for event in top-left corner -->
          <VIcon
            v-if="!isEmpty(booking.eventDetails)"
            class="top-left-icon event-icon"
            color="black"
          >
            mdi-star-outline
          </VIcon>

          <VCardText class="card-content pt-2 d-flex justify-space-between">
            <div>
              <div class="booking-header d-flex justify-center align-center">
                <h3 class="activity">
                  {{ moment(booking.startTime).format("hh:mm A") }}
                </h3>
              </div>
              <div class="booking-details d-flex justify-center align-center">
                <div class="mt-2">
                  <span class="info">
                    {{ getDay(booking.startTime) }}
                  </span>
                </div>
              </div>
              <div class="booking-details d-flex justify-center">
                <div class="mt-2">
                  <span class="info">
                    <VIcon icon="mdi-location" />
                    {{ getLocation(booking?.expand?.area) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex align-end">
              <IconBtn
                @click.stop="
                  () => {
                    selectedBookingId = booking?.id;
                    isQrCodeVisible = true;
                  }
                "
                color="black"
                icon="mdi-qrcode"
              />
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <!-- Booking dialog -->
    <BookingStepper
      v-model:isDrawerOpen="isBookingStepperVisible"
      :booking="editBookingData"
      :recurring-details="recurringDetails"
      :activity-list="activityList"
      :area-list="listArea"
      :memberList="usersList"
      :location-list="locationList"
      :current-location="currentLocation"
      :current-logged-in-roles="getMyRole"
      :membership="userInfo?.expand?.membership"
      :user-id="userId"
      :permission="permissionListMap?.bookings"
      :coupon-value="globalSetup?.couponValue"
      @loadUsersList="getUserList()"
      @update:isDrawerOpen="closeDialog"
      @delete-booking="deleteBooking($event)"
      @addNewCardDialog=""
      @closeDialog="
        (editBookingData = {}),
          (recurringDetails = {}),
          (isBookingStepperVisible = $event)
      "
      @reloadBookingList="getPriorityBookings(userId)"
    />

    <!-- Event dialog -->
    <AddEditEvents
      :is-drawer-open="addEditEventDialog"
      :event="eventData"
      :recurring-details="eventRecurringDetails"
      :activity-list="activityList"
      :area-list="listArea"
      :memberList="usersList"
      :location-list="locationList"
      :current-location="currentLocation"
      :area-plural="globalSetup?.areaPlural || 'Bays'"
      :area-singular="globalSetup?.areaSingular || 'Bay'"
      :current-logged-in-roles="getMyRole"
      :membership="userInfo?.expand?.membership"
      :user-id="userId"
      :permission="permissionListMap?.bookings"
      :coupon-value="globalSetup?.couponValue"
      @closeDialog="addEditEventDialog = false"
    />

    <!-- Suggestion edit dialog -->
    <EditConfirmBookingDialog
      :is-visible="isConfirmEditBookingDialog"
      :title="confirmEditDialog?.title"
      :alertMessage="confirmEditDialog?.alertMessage"
      @confirmEdit="
        ($event) => {
          isConfirmEditBookingDialog = false;
          if (confirmEditDialog?.type === 'Event') {
            eventRecurringDetails = {
              ...eventRecurringDetails,
              isEditAll: $event === 'ALL' ? true : false,
            };
            handleEventEdit(selectedEvent);
          } else {
            handleBookingEdit($event);
          }
        }
      "
      @closeDialog="
        (editBookingData = {}),
          (eventRecurringDetails = {}),
          (isConfirmEditBookingDialog = $event)
      "
    />

    <!-- QR code Dialog -->
    <QrCode
      :is-visible="isQrCodeVisible"
      :qr-value="selectedBookingId"
      @closeDialog="isQrCodeVisible = false"
    />
  </VCard>
</template>

<style scoped lang="scss">
.bookings-container {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: var(--text-color);
}

.title {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.loading-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.animated-shimmer {
  position: relative;
  overflow: hidden;
  background: var(--skeleton-bg);
}

.shimmer {
  background: var(--shimmer-bg);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.booking-skeleton {
  padding: 8px;
  border-radius: 10px;
}

.skeleton-header {
  height: 15px;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: var(--skeleton-bg);
}

.skeleton-chips {
  display: flex;
  gap: 6px;
  margin-bottom: 6px;
}

.skeleton-chip {
  height: 10px;
  border-radius: 16px;
  background-color: var(--skeleton-bg);
  flex: 1;
}

.skeleton-details {
  line-height: 1.5;
}

.skeleton-detail {
  height: 15px;
  margin-bottom: 3px;
  border-radius: 4px;
  background-color: var(--skeleton-bg);
}

// Other styles remain unchanged

.booking-card {
  position: relative;
  border-radius: 10px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  overflow: hidden;
  background-color: rgb(var(--v-theme-dialogHeaderColor));
}

.booking-card:hover {
  transform: scale(1.05);
  background-color: rgb(var(--v-theme-primary)) !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.due-card {
  background-color: rgb(var(--v-theme-error)) !important;
}

.top-right-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.top-left-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.recurring-icon {
  font-size: 24px;
}

.event-icon {
  font-size: 24px;
}

.card-content {
  font-size: 19px;
}
</style>
