<script setup lang="ts">
import QrcodeVue from "qrcode.vue";
import type { Level, RenderAs, GradientType } from "qrcode.vue";

// interface
interface Prop {
  isVisible: boolean;
  qrValue: string;
}

interface Emit {
  (e: "closeDialog", val: boolean): void;
}

// Props
const props = defineProps<Prop>();

// Emit
const emit = defineEmits<Emit>();

// data
const level = ref<Level>("M");
const renderAs = ref<RenderAs>("svg");
const background = ref("#ffffff");
const foreground = ref("#000000");
const margin = ref(2);
const gradient = ref(false);
const gradientType = ref<GradientType>("linear");
const gradientStartColor = ref("#000000");
const gradientEndColor = ref("#38bdf8");
</script>

<template>
  <VDialog :model-value="props?.isVisible" persistent
    class="d-flex justify-center align-center qr-dialog pair_terminal_dialog">
    <VCard height="auto" width="400" class="rounded-lg shadow-md bg-gradient-to-br from-blue-50 to-blue-100">
      <div class="d-flex align-center justify-space-between bg-dialogHeaderColor">
        <VCardTitle>Scan to Pay</VCardTitle>
        <VBtn icon="mdi-close" size="large" variant="text" class="rounded-0" color=""
          @click="emit('closeDialog', false)"></VBtn>
      </div>
      <div class="px-6 py-4 text-center">
        <qrcode-vue text="testing" :value="`BT#${qrValue}`" :level="level" :render-as="renderAs"
          :background="background" :foreground="foreground" :gradient="gradient" :gradient-type="gradientType"
          :gradient-start-color="gradientStartColor" :gradient-end-color="gradientEndColor" :margin="margin" size="320"
          class="qr-code_container" />
        <div class="mt-4"></div>
        <p class="font-weight-medium">QRCode is valid for single booking only</p>
        <p class="font-weight-medium">Screenshot on your phone if preferred</p>
      </div>
    </VCard>
  </VDialog>
</template>

<style scoped>
.qr-dialog {
  backdrop-filter: blur(6px);
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.border-b {
  border-bottom: 1px solid #e5e7eb;
}

.rounded-lg {
  border-radius: 12px;
}

.qr-code_container {
  width: -webkit-fill-available;
}
</style>
