export const usePermission = () => {
  /**
   * Checks if a specific field has dependencies based on user permissions.
   *
   * @param fieldName - The name of the field to check for dependencies.
   * @returns A boolean indicating whether the field has dependencies.
   *
   * @remarks
   * This function uses a switch statement to check the `fieldName` against predefined cases.
   * If the `fieldName` matches "POS", it checks the user's permission for the "dockets" field.
   * If the permission is not "Create", it returns `true`.
   * If the `fieldName` does not match any case, it returns `false`.
   *
   * If an error occurs during the execution, it logs the error to the console.
   */
  const checkDependencies = (fieldName: string, permission: any) => {
    try {
      switch (fieldName) {
        case "POS":
          return (
            permission &&
            permission["dockets"] &&
            permission["dockets"] != "Create"
          );
        case "Dockets":
          return (
            permission &&
            permission["dockets"] &&
            permission["dockets"] != "Create" &&
            permission["dockets"] != "View"
          );
        default:
          return false;
      }
    } catch (e) {}
  };

  /**
   * Determines whether a specific menu item should be disabled based on user permissions.
   *
   * @param item - The menu item to check.
   * @returns A boolean indicating whether the menu item should be disabled.
   *
   * @remarks
   * This function checks the user's permissions against a predefined mapping of menu item titles to permission keys.
   * It also considers any dependencies that may affect the menu item's availability.
   *
   * The function uses the following logic:
   * - If the menu item has dependencies (checked by the `checkDependencies` function), it returns `true`.
   * - If the menu item has a corresponding permission key in the `permissionMap`, it checks the user's permission for that key.
   *   If the permission is "None", it returns `true`.
   * - If none of the above conditions are met, it returns `false`.
   */
  const isDisable = (item: any, permission: any) => {
    const permissionMap: { [key: string]: string } = {
      Users: "users",
      Reports: "reporting",
      Bookings: "bookings",
      "Roles & Permissions": "roles",
      Activities: "activities",
      "Gift Cards": "giftCards",
      Areas: "areas",
      Doors: "doors",
      Memberships: "memberships",
      Locations: "locations",
      Dockets: "dockets",
      Products: "products",
    };

    const permissionKey = permissionMap[item.title];
    const dependencies = checkDependencies(item.title, permission);
    // console.log(dependencies, item.title);
    return (
      dependencies ||
      (permissionKey && permission && permission[permissionKey] === "None")
    );
  };

  return {
    isDisable,
  };
};
