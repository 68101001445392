export default [
  { heading: "Admin", permissionRequired: "canSeeAdmin" },
  {
    title: "Users",
    to: { name: "user" },
    icon: { icon: "mdi-account-group-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Staffing",
    to: { name: "staffing" },
    icon: { icon: "mdi-account-clock-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Events",
    to: { name: "events" },
    icon: { icon: "mdi-calendar-star" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Area Status",
    to: { name: "areastatus" },
    icon: { icon: "mdi-list-status" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Gift Cards",
    to: { name: "giftcards" },
    icon: { icon: "mdi-wallet-giftcard" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Discount Codes",
    to: { name: "discount-codes" },
    icon: { icon: "mdi-percent-box-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Products",
    to: { name: "products" },
    icon: { icon: "mdi-food-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Dockets",
    to: "dockets",
    icon: { icon: "mdi-cart" },
    disable: false,
    permissionRequired: "canSeeApps",
  },
  {
    title: "Reports",
    to: { name: "reports" },
    icon: { icon: "mdi-chart-bar" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Logs",
    to: null,
    icon: { icon: "mdi-file-document-outline" },
    disable: true,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Access Control",
    to: { name: "accesscontrol" },
    icon: { icon: "mdi-door-sliding-lock" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "FAQ",
    to: { name: "faqs-admin" },
    icon: { icon: "mdi-frequently-asked-questions" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },

  { heading: "Setup", permissionRequired: "canSeeAdmin" },
  {
    title: "Location",
    to: { name: "location" },
    icon: { icon: "mdi-map-marker-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Activities",
    to: { name: "activities" },
    icon: { icon: "mdi-golf" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Areas",
    to: { name: "areas" },
    icon: { icon: "mdi-map-marker-circle" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Memberships",
    to: { name: "memberships" },
    icon: { icon: "mdi-account-heart-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Coupon Types",
    to: { name: "coupontypes" },
    icon: { icon: "mdi-ticket" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Roles & Permissions",
    to: { name: "roles" },
    disable: false,
    icon: { icon: "mdi-shield-account-outline" },
    // children: [
    //   { title: "Roles", to: null, disable: true },
    //   { title: "Permissions", to: null, disable: true },
    // ],
    permissionRequired: "canSeeAdmin",
  },
  {
    title: "Settings",
    to: { name: "settings" },
    icon: { icon: "mdi-cog" },
    disable: false,
    permissionRequired: "canSeeAdmin",
  },
];
